import React from "react"
import Container from "../components/container/container";
import SEO from "../components/seo";
import YM from "../components/analytics/ym";
import Potolki from "../components/articles/potolki";

const PotolkiPage = () => (
    <Container>
        <SEO
            title="complexstroy.by | Секреты натяжных потолков"
            description="Технология монтажа натяжного потолка — как выполнить установку своими руками"/>
        <YM />
        <Potolki />
    </Container>
);

export default PotolkiPage
