import React from "react"
import styles from "./article-pages.module.css"
import {Link} from "gatsby";
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing'
import LinkButton from "../buttons/link-button/link-button";
import Background from "../../../static/articles/potolki/bg.jpg";

const Potolki = () => {

    return (
        <ModalRoutingContext.Consumer>
            {({ modal, closeTo }) => (
                <div className={modal ? styles.articleModal : styles.articlePage}>
                    {modal ? (
                        <Link className={"close-modal"} to={"/#articles"}>&times;</Link>
                    ) : "" }
                    <div className={styles.articleBackground} style={{
                        backgroundImage: `url(${Background})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center'
                    }}>
                        <div className={styles.imageBackground}>
                            <p className={styles.backgroundText}>
                                Секреты натяжных потолков
                            </p>
                        </div>
                    </div>
                    <div className={styles.content}>
                        <h3>Технология монтажа натяжного потолка — как выполнить
                            установку своими руками?</h3>

                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/potolki/1.jpg"/>
                        </div>

                        <p>В этой статье разберем, что же такое, технология монтажа натяжного потолка. Наверное,
                            вы не раз замечали, что бывает в тех случаях, когда за какую-то
                            работу принимаются люди, не имеющие соответствующего опыта. Одно дело, если это
                            работа, выполняемая для себя и совсем другое, если человек старается оказывать
                            услуги другим. Часто самодеятельность начинается там, где есть желание немного
                            сократить на ремонте личные расходы.</p>
                        <p>Это неплохо и заслуживает уважения. Вопрос лишь в том, что движет человеком
                            желающим сэкономить. При недостатке скромности расходы во время проведения
                            работ могут превысить ожидаемые многократно, несмотря на все приложенные
                            усилия. Без опыта можно испортить дорогое сырье, а затем — еще раз платить
                            за новое. Ну а далее — оплачивать работу специалистов, помня
                            о неприятном личном опыте.</p>
                        <p>Чтобы так не получилось, давайте рассмотрим шаг за шагом технологию монтажа
                            натяжного потолка и воспользуемся помощью сопроводительных фото, помогающих
                            яснее представить, что от нас ожидается на том или ином этапе работ.
                            Разобравшись в деталях, станет понятно, насколько данная задача является
                            посильной для самостоятельного её разрешения.</p>

                        <h2>Используемые материалы и их характеристики</h2>

                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/potolki/2.jpg"/>
                        </div>

                        <p>Чтобы хорошо уяснить технические моменты выполнения работ желательно немного больше
                            узнать о материалах, с которыми придется работать.</p>
                        <p>В первую очередь речь идет поливинилхлоридной пленке (ПВХ), являющейся основой
                            будущего натяжного потолка и используемых профилях или багетах, на которых
                            эта пленка фиксируется на нужном уровне.</p>

                        <h3>Натяжные полотна</h3>

                        <p>Весь набор виниловых полотен для натяжных потолков можно разделить на несколько
                            видов, отличающихся характером фактуры:</p>
                        <ul>
                            <li>глянцевые полотна. Они самые популярные и вполне доступны по цене.
                                Глянцевое покрытие имеет высокую отражающую способность и существенно
                                влияет на восприятие оборудованного им покрытия и помещения
                                в целом. Все находящиеся в комнате предметы на таком полотне
                                отражаются практически без искажений, а сама комната кажется более высокой
                                и просторной;
                            </li>
                            <li>матовое полотно. Оно тоже очень популярно у заказчиков, так, как идеально
                                ровный натяжной потолок, выполненный в спокойных матовых тонах, любой
                                комнате непременно добавляет теплоты и уюта;
                            </li>
                            <li>сатиновые полотна<em>. </em>Это покрытие — нечто среднее между матовым
                                и глянцевым.
                            </li>
                        </ul>
                        <blockquote><p><strong>На заметку</strong>: Преимущества сатиновых полотен особенно
                            ярко видны при хорошо подобранной и правильно смонтированной системе
                            осветительных приборов. Именно освещение помогает ощутить все прелести сатиновой
                            фактуры.</p></blockquote>

                        <h3>Несущие профили</h3>

                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/potolki/3.jpg"/>
                        </div>

                        <p>До того, как приняться за установку натяжного полотна, на стенах уже
                            должны быть установлены багеты, которые и будут удерживать полотно
                            в натянутом состоянии. На них во время всего периода эксплуатации
                            натяжного потолка будет оказываться постоянная отрывная нагрузка, создаваемая
                            натянутым полотном. Это вынуждает заранее задумываться о качестве профилей
                            и тщательно их подбирать.</p>
                        <p>Поскольку существует несколько способов крепления полотна, устройство профилей
                            в каждом отдельном случае может отличаться. Материал, из которого они
                            изготовлены, тоже может быть различным. Чаще всего в качестве сырья для
                            изготовления профилей под эластичные потолки применяют пластики и металл,
                            например, алюминий.</p>
                        <p>Посмотрим, что же еще нужно знать о применяемых в технологии монтажа
                            багетах:</p>
                        <ul>
                            <li>существует технология с кулачковым креплением. Его применение подразумевает
                                использование профиля с пружинным механизмом и отдельными
                                стационарными элементами, называемыми кулачками. Край полотна, сложенный вдвое,
                                проталкивается внутрь профиля, либо не острым шпателем, либо монтажной
                                лопаткой. В момент натяжения полотна оно начинает оказывать давление
                                на элементы подвижной части кулачка, способствуя, таким образом, усилению
                                его прижимного давления на полотно. В результате прижимания подвижной
                                части к стационарной, полотно надежно фиксируется между ними
                                и удерживается в натянутом состоянии;
                            </li>
                            <li>другим распространенным методом фиксации полотна является клиновый, применяемый
                                при использовании профиля с двумя посадочными пазами, один из которых
                                используется для посадки так называемого клина, а другой — для
                                установки декоративного элемента в виде продольной планки. В этом
                                случае пленку закладывают в паз и прижимают клином. И так
                                по всему периметру помещения. На следующем этапе в соседний паз
                                устанавливаются декоративные элементы, придающие крепежному устройству
                                дополнительной жесткости. Поскольку края полотна могут выглядывать
                                из профиля, лишний материал необходимо аккуратно удалить, срезав его острым
                                ножом;
                            </li>
                            <li>наиболее надежным и чаще всего применяемым способом установки натяжных
                                полотен, является гарпунный способ. Здесь есть свои нюансы. Первое существенное
                                отличие от выше описанных способов монтажа состоит в том, что
                                на ПВХ-полотно, изготовленное по заданному размеру, со всех
                                сторон заранее приваривается продольный профиль, называемый гарпуном. Это
                                происходит не на месте работ, а на заводе и требует
                                достаточно много времени. Поэтому выполнения подобного заказа приходится ждать
                                несколько недель, а то и более того. Приваренный к полотну
                                гарпунный профиль вкладывается в нужный паз на багете,
                                в результате чего крючковидная часть гарпуна просто защелкивается внутри
                                багета и за счет этого надежно фиксируется. После установки полотна
                                со всех сторон, монтажный механизм профиля закрывается декоративными
                                плинтусами.
                            </li>
                        </ul>
                        <blockquote><p><strong>Внимание</strong>: Преимущество гарпунного крепления состоит
                            в том, что в любой момент натянутое потолочное покрытие вы можете
                            снять без неприятных для него последствий и после выполнения необходимых
                            процедур вернуть на место. Иные способы монтажа свободное снятие полотна после
                            установки не предусматривают.</p></blockquote>

                        <h2>Монтажные работы по установке натяжного потолка</h2>

                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/potolki/4.jpg"/>
                        </div>

                        <p>Технология монтажа натяжного потолка предусматривает выполнение ряда задач:</p>
                        <ol>
                            <li>Разметка. Она выполняется с максимальной точностью, поскольку любая
                                оплошность на этом этапе работ рано или поздно даст о себе знать.
                                Наиболее качественная разметка выполняется с применением лазерного уровня,
                                длинного правила и чоклайна (прочная окрашиваемая разметочная нить).
                                Сначала на нужной высоте на одной из стен ставится метка, которая
                                при помощи лазерного уровня переносится на все остальные углы. Потом все
                                метки соединяются сплошными линиями, наносимыми чоклайном.
                            </li>
                            <li>Вдоль линий разметки крепится несущий профиль из металла или пластика. Для
                                этого подходят забивные шурупы с дюбелями размером 6×40 мм. Шаг
                                крепления багетов может меняться в зависимости от плотности стен.
                                Среднее расстояние между багетными крепежами для бетонной стены составляет
                                8-10 см, а в местах их соединения — 5-7 см.
                            </li>
                            <li>При установке багета нужно быть предельно внимательным из-за вероятности
                                попадания шурупов в электропроводку. Чтобы этого избежать желательно
                                до начала монтажа багетов определить наличие и уровень залегания
                                электропроводов. В определении места нахождения проводов может помочь
                                расположение распределительных коробок, включателей и розеток. Намного
                                надежнее определять местонахождение электропроводов в стене
                                металлоискателем.
                            </li>
                            <li>Если существующая проводка или другая причина не позволяют устанавливать
                                профили на стену, применяют такой же профиль, предусмотренный для
                                установки на потолок.
                            </li>
                            <li>Установка приборов освещения требует закладки консольных элементов заводского
                                образца или изготовленных самостоятельно. При изготовлении консолей
                                в домашних условиях используется фанера большой толщины или ОСП. Обычная
                                доска не так хороша в этом случае, как может показаться на первый
                                взгляд. Она если не сразу, то со временем может расколоться
                                на части и не удержать крепежные элементы.
                            </li>
                            <li>Приступая к установке натяжного покрытия, нужно сначала его развернуть,
                                а затем на специальных щипцах подвесить к профилям поближе
                                к углам. Такое положение ПВХ-пленки поможет его равномерно разогреть
                                до нужной температуры (60-70℃). Все, что может быть повреждено такой
                                температурой, необходимо удалить из помещения установки.
                            </li>
                            <li>Далее выполняется сам разогрев полотна при помощи тепловой пушки. Этот
                                инструмент довольно дорогой — от 5 до 35 тыс. руб., что
                                часто останавливает домашних мастеров и заставляет прибегать к помощи
                                наемных рабочих. Кроме этого применение тепловой пушки требует определенного
                                опыта, отсутствие которого может вызвать повреждение пленки.
                            </li>
                            <li>Разогретая пленка лопаткой или шпателем продевается в профиль
                                и фиксируется со всех сторон. Наличие морщин не должно вас
                                смущать, так как они после остывания полотна и его натяжения полностью
                                исчезнут.
                            </li>
                            <li>Зазор, образующийся на стыке стен и нового потолка, закрывают
                                декоративной планкой.
                            </li>
                            <li>В точках крепления осветительных приборов устанавливают уплотнительное
                                кольцо и внутри его вырезают монтажное отверстие в пленке.
                            </li>
                        </ol>

                        <div style={{textAlign: 'center', marginTop: '40px'}}>
                            <LinkButton text={'Вернуться на сайт'} path={modal ? "/#articles" : ""}/>
                        </div>
                    </div>
                </div>
            )}
        </ModalRoutingContext.Consumer>
    );
};

export default Potolki
